/* You can add global styles to this file, and also import other style files */
@import url('./ethele-theme.css');
html, body { height: 100%; }
body { margin: 0 !important; font-family: Roboto, "Helvetica Neue", sans-serif; }



body {
    animation: fadeInAnimation ease 1s;
    // background-image: linear-gradient(to left,grey, orange);
}

mat-card {
    animation: fadeInAnimation ease 1s;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}


/* width */
::-webkit-scrollbar {
    width: 4px;
}
::-webkit-scrollbar:horizontal {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ff5b0c; 
}

.block-button > label {
    user-select: none;
}

// mat-card {
//     background-color: #3f4141 !important;
// }